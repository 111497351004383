.preview-img:hover {
  opacity: 1;
}

.preview-img.active {
  opacity: 1;
}

.preview-img {
  opacity: 0.6;
}
